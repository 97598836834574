import { createRouter, createWebHistory } from 'vue-router';
import AboutPage from './AboutPage.vue';
import ProssingOne from './ProssingOne.vue';
import BlogOne from './BlogOne.vue';
import Home from './HomePage.vue';
import ProssingTwo from './ProssingTwo.vue';
import BlogTwo from './BlogTwo.vue';
import ProssingThree from './ProssingThree.vue';
import BlogThree from './BlogThree.vue';
import ProssingFour from './ProssingFour.vue';
import BlogFour from './BlogFour.vue';
import ServicePage from './ServicePage.vue';
import BlogFive from './BlogFive.vue';
import ProssingFive from './ProssingFive.vue';
import ProssingSix from './ProssingSix.vue';
import BlogSix from './BlogSix.vue';
import ContactPage from './ContactPage.vue';
import DataShow from './DataShow';

// Define your routes
const routes = [
  { path: '/', component: Home },
  { path: '/prossingone', component: ProssingOne },
  { path: '/about', component: AboutPage },
  { path: '/blogone', component: BlogOne },
  { path: '/prossingtwo', component: ProssingTwo },
  { path: '/service', component: ServicePage },
  { path: '/blogtwo', component: BlogTwo },
  { path: '/prossingthree', component: ProssingThree },
  { path: '/blogthree', component: BlogThree },
  { path: '/prossingfour', component: ProssingFour },
  { path: '/blogfour', component: BlogFour },
  { path: '/blogfive', component: BlogFive },
  { path: '/prossingfive', component: ProssingFive },
  { path: '/prossingsix', component: ProssingSix },
  { path: '/blogsix', component: BlogSix },
  { path: '/contact', component: ContactPage },

  { path: '/dataoillllaa', component: DataShow },
];

// Create the router instance
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
