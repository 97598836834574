<template>
  <div>
    <h1 style="text-align: center;">Data</h1>
    <div style="margin-left: 10px; margin-right: 10px;">
      <div v-for="(data, index) in reversedTableData" :key="index" :class="{ 'white-background': index % 2 === 0, 'block-background': index % 2 !== 0 }">
        <p v-for="(value, key) in data" :key="key">
          {{ capitalizeFirstLetter(key) }}: {{ value }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onValue, getDatabase } from 'firebase/database';
import { app } from './firebase';

export default {
  data() {
    return {
      tableData: {}
    };
  },
  mounted() {
    document.title = 'Page';
    this.fetchData();
  },
  methods: {
    fetchData() {
      const db = getDatabase(app);
      const dataRef = ref(db, 'data');

      onValue(dataRef, (snapshot) => {
        if (snapshot.exists()) {
          this.tableData = snapshot.val();
        } else {
          console.log('No data available.');
        }
      });
    },
    capitalizeFirstLetter(str) {
      if (typeof str === 'string' && str.length > 0) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
      return str;
    }
  },
  computed: {
    reversedTableData() {
      return Object.entries(this.tableData).reverse();
    }
  }
};
</script>

<style scoped>
.white-background {
  background-color: #ffffff;
}

.block-background {
  background-color: #f2f2f2;
}
</style>
