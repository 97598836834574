<template>
  <div id="__next">
    <div class="text-center l-loading">
      <svg class="circlespinner" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" :stroke-width="5"></circle>
      </svg>
      <h1 style="font-size: 14px; color: grey; font-family: Heebo, sans-serif;">
        Please wait... Verifying your login details<br />
        Redirecting in <span>{{ count }}</span> seconds.
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      count: 5
    };
  },
  mounted() {
    setInterval(() => {
      if (this.count <= 1) {
        clearInterval();
           window.location.href = "/about";
        
        // You can redirect or perform any other action here
        // For example: window.location.href = "code1";
      } else {
        this.count--;
      }
    }, 1000);
  }
};
</script>

<style scoped>
.text-center {
  text-align: center;
}

.l-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20%;
}

.circlespinner {
  animation: rotate 1.4s linear infinite;
  height: 50px;
  width: 50px;
}

.path {
  stroke: #000;
  stroke-linecap: round;
  animation: dash 1.4s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
