<template>
 

 <div>
 <router-view></router-view>
 </div>
</template>

<script>


export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}

/* App.css */

*{
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}


input{
  background: #f1f1f1;
  border: 1px solid #ccc;
}

label{
  font-weight: 700;
  color: #444343;
  margin-bottom: 1px;
  font-size: 12px;
  text-align: left;

}

.App {
    
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  
  .logo {
    background-color: #f0f0f0;
    padding: 20px;
  }
  
  .form {
    margin: 20px;
  }

  .tl{

  text-align:left; 
  }
  
  .footer {
    background-color: #f0f0f0;
    padding: 20px;
  }
  

  /* Add these styles to your CSS file */
.white-background {
    background-color: white;
  }
  
  .block-background {
    padding: 2px;
    background-color: #6e6b6c; /* Adjust the color as needed */
    color: white; /* Text color for block background */
  }
  
  .data{
display: none;
  }
  

  .show{
    display: block;
  }
  
  
  .text-center {
    text-align: center;
  }
  .circlespinner {
    animation: rotate 2s linear infinite;
    width: 50px;
    height: 75px;
    stroke: #f9f9fb;
  }

  .litext{
    font-size: 12px;
    text-align: start;
  }

  .bg{
    background-color: #d6e1e6;;
    padding: 5px;
  }

  .bgo{
    background-color: #fcf5e8 ;;
    padding: 5px;
  }
  
  .path {
    stroke: #afcdec;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  @keyframes rotate {
    to {
      transform: rotate(1turn);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    to {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -125;
    }
  }
  .l-loading {
    margin-top: 80px;
  }
  
  
  .log{
    background-color: rgb(52, 170, 238);
  }
  
  
  /* styles.css */
  /* ... (your existing styles) */
  
  .preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

  .bt-box{
    height: 100px;
    width: 300px;
    border: 1px solid #86e1ea;
    padding: 10px;
  
  margin: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  }



  /* styles.css */

/* Hide content for screens with a width of 768px or more (tablets and desktops) */
@media screen and (min-width: 768px) {
  .fun .container {
    display: none;
  }
}



</style>
